<template>
  <template-block
    mod-class
    content-class="order-was-create"
    title-page="Спасибо"
  >
    <div class="d-flex align-items-center justify-content-center">
      Задача успешно создана
    </div>
  </template-block>
</template>

<script>
import TemplateBlock from "@/components/TepmplateBlock";

export default {
  name: "OrderWasCreate",
  components: {
    TemplateBlock
  }
};
</script>
